<template>
  <tr>
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <img
        v-if="options.company.selected === 'Lengow'"
        :src="`${options.baseUrl}/img/icons/website.png`"
        style="width: 15px; vertical-align: middle;"
        alt="website"
      >
      <img
        v-if="options.company.selected === 'Netrivals'"
        :src="`${options.baseUrl}/img/icons/netrivals/website.png`"
        style="width: 15px; vertical-align: middle;"
        alt="website"
      >
    </td>
    <td
      v-if="options.company.selected === 'Lengow'"
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        href="https://www.lengow.com"
        target="_blank"
        style="text-decoration: none!important; color: white; vertical-align: middle;"
      ><span
        style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"
      >www.lengow.com</span></a>
      <span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"> | </span>
      <a
        href="https://blog.lengow.com"
        target="_blank"
        style="text-decoration: none!important; color: white; vertical-align: middle;"
      ><span
        style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"
      >blog.lengow.com</span></a>
    </td>
    <td
      v-if="options.company.selected === 'Netrivals'"
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        href="https://www.netrivals.com/"
        target="_blank"
        style="text-decoration: none!important; color: white; vertical-align: middle;"
      ><span
        style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"
      >www.netrivals.com</span></a>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Website',

  computed: {
    ...mapState(['options'])
  }
}
</script>
