<template>
  <tr>
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <img
        v-if="options.company.selected === 'Lengow'"
        :src="`${options.baseUrl}/img/icons/office.png`"
        style="width: 15px; vertical-align: middle;"
        alt="office"
      >
      <img
        v-if="options.company.selected === 'Netrivals'"
        :src="`${options.baseUrl}/img/icons/netrivals/office.png`"
        style="width: 15px; vertical-align: middle;"
        alt="office"
      >
    </td>
    <td
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        v-if="options.office.selected === 'Nantes'"
        href="https://www.google.com/maps/place/Lengow/@47.2078532,-1.5369703,18.46z/data=!4m13!1m7!3m6!1s0x4805eecbcc0f2cf9:0x62279a0d7d4f0149!2sEHPAD+-+Viviani,+6+Rue+Ren%C3%A9+Viviani,+44200+Nantes!3b1!8m2!3d47.2079809!4d-1.5359687!3m4!1s0x4805eeae2b9484ad:0x8b24d7c72ae55528!8m2!3d47.2077315!4d-1.536193"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      ><span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;">6 rue René Viviani, 44200 Nantes, France</span></a>
      <a
        v-if="options.office.selected === 'Paris'"
        href="https://www.google.com/maps/place/4+Rue+Jules+Lefebvre,+75009+Paris/data=!4m2!3m1!1s0x47e66e4b97c42ba1:0x8e53d3c7c461d627?sa=X&ved=2ahUKEwiEna3dga3tAhWwxoUKHYwZAZoQ8gEwAHoECAUQAQ"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      ><span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;">4 rue Jules Lefebvre, 75009 Paris, France</span></a>
      <a
        v-if="options.office.selected === 'Barcelona'"
        href="https://www.google.com/maps/place/netRivals+%7C+Price+Intelligence/@41.428272,2.1828513,17z/data=!3m2!4b1!5s0x12a4bcd6367896cb:0x7066cd2cdf2fcce3!4m5!3m4!1s0x12a4b4e43629c015:0x52256ff55ff8b986!8m2!3d41.428268!4d2.18504"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      ><span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;">Avda Meridiana 358 14th floor - 08027 Barcelona</span></a>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Office',

  computed: {
    ...mapState(['options'])
  }
}
</script>
