<template>
  <tr v-if="mainFields[2].value !== ''">
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <span style="vertical-align: middle;">
        <img
          v-if="options.company.selected === 'Lengow'"
          :src="`${options.baseUrl}/img/icons/email.png`"
          style="width: 15px; vertical-align: middle;"
          alt="email"
        >
        <img
          v-if="options.company.selected === 'Netrivals'"
          :src="`${options.baseUrl}/img/icons/netrivals/email.png`"
          style="width: 15px; vertical-align: middle;"
          alt="email"
        >
      </span>
    </td>
    <td
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <a
        :href="`mailto:${mainFields[2].value}`"
        target="_blank"
        style="text-decoration: none; color: white; vertical-align: middle;"
      ><span
        style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;"
      >{{ mainFields[2].value }}</span></a>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Email',

  computed: {
    ...mapState(['options'])
  },

  props: {
    mainFields: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>
