<template>
  <tr v-if="options.ticket.show">
    <td
      width="25"
      valign="top"
      style="vertical-align: middle; line-height: 1; width: 25px;"
    >
      <img
        :src="`${options.baseUrl}/img/icons/ticket.png`"
        style="width: 15px; vertical-align: middle;"
        alt="ticket"
      >
    </td>
    <td
      valign="top"
      style="vertical-align: middle; line-height: 1; font-size: 9pt;"
    >
      <span style="vertical-align: middle; color: rgb(85,85,85); font-size: 9pt;">Submit a support request for <a
        href="https://help.lengow.com/hc/en-us/requests/new"
        target="_blank"
        style="text-decoration: none; color: rgb(0,10,49);"
      >Lengow</a> or <a
        href="https://help.netrivals.com/hc/requests"
        target="_blank"
        style="text-decoration: none; color: rgb(0,10,49);"
      >NetRivals</a></span>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Ticket',

  computed: {
    ...mapState(['options'])
  }
}
</script>
